/* eslint-disable react/prop-types */
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from "yup";
// eslint-disable-next-line import/no-unresolved
import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup,} from "@mui/material";
import { apipost } from "../../service/api";
import Palette from '../../theme/palette';


const VerifyLogbook = (props) => {
    const { open, handleClose, setUserAction } = props
    const [isLoading, setIsLoading] = useState(false);
    const params = useParams()

    const user = JSON.parse(sessionStorage.getItem('user'))

    // -----------  validationSchema
    const validationSchema = yup.object({
        IsVehicleInspected: yup.string().required("Vehicle Inspection Status is required"),
        IsApproved: yup.string().required("Approval Status is required"),
        IsLogBookVerified: yup.string().required("Logbook Verification Status is required"),
    });
    // -----------   initialValues
    const initialValues = {
        IsVehicleInspected: "Yes",
        IsApproved: "Yes",
        IsLogBookVerified: "Yes",
        AdditionalComments: "",
    };

    // add email api
    const verifyLogbook = async (values) => {
        setIsLoading(true)

        try {
            const data = {
                IsApproved: values.IsApproved === 'Yes' ? true : false,
                IsLogBookVerified:  values.IsLogBookVerified === 'Yes' ? true : false,
                IsVehicleInspected: values.IsVehicleInspected === 'Yes' ? true : false,
                AdditionalComments: values.AdditionalComments,
            };
            const result = await apipost(`api/dmvic/verifyLogBook/${params.id}`, data)
            setUserAction(result)
            if (result && (result.status >= 200 && result.status < 300)) {
                formik.resetForm();
                handleClose();
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }
    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {
            verifyLogbook(values);
            resetForm();
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Issue Certificate</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} >
                                    <FormControl fullWidth>
                                    <FormLabel>Is Vehicle Inspected <span style={{ color: "red" }}>*</span></FormLabel>
                                    <RadioGroup row name="IsVehicleInspected" onChange={formik.handleChange} value={formik.values.IsVehicleInspected} defaultValue={"Now"}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.IsVehicleInspected && formik.errors.IsVehicleInspected}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} >
                                    <FormControl fullWidth>
                                    <FormLabel>Is Approved<span style={{ color: "red" }}>*</span></FormLabel>
                                    <RadioGroup row name="IsApproved" onChange={formik.handleChange} value={formik.values.IsApproved} defaultValue={"Yes"}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.IsApproved && formik.errors.IsApproved}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} >
                                    <FormControl fullWidth>
                                    <FormLabel>Is LogBook Verified <span style={{ color: "red" }}>*</span></FormLabel>
                                    <RadioGroup row name="IsLogBookVerified" onChange={formik.handleChange} value={formik.values.IsLogBookVerified} defaultValue={"Yes"}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                    <FormHelperText style={{ color: Palette.error.main }}>{formik.touched.IsLogBookVerified && formik.errors.IsLogBookVerified}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>Additional Comments </FormLabel>
                                    <TextField
                                        name='AdditionalComments'
                                        fullWidth
                                        multiline
                                        value={formik.values.AdditionalComments}
                                        onChange={formik.handleChange}
                                        error={formik.touched.AdditionalComments && Boolean(formik.errors.AdditionalComments)}
                                        helperText={formik.touched.AdditionalComments && formik.errors.AdditionalComments}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='secondary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                        color="error"
                    >
                        Cancle
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default VerifyLogbook