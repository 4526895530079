import AddIcon from "@mui/icons-material/Add";
import AttachmentIcon from '@mui/icons-material/Attachment';
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import TableStyleTwo from '../../../components/TableStyleTwo';
import { constant } from '../../../constant';
import { apidelete, apiget, apipost } from '../../../service/api';
import AddDocument from './Add';
import EncryptionCode from "src/components/document/showEncryptioncode";

const PolicyDocumentPage = ({ rows, toggleVisibilityPolicyDoc, isVisiblePolicyDoc, setUserAction, _id }) => {

    const [openAdd, setOpenAdd] = useState(false);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const [openAddEncryptionToken, setOpenAddEncryptionToken] = useState(false);
    const handleOpenAddEncryptionToken = () => setOpenAddEncryptionToken(true);
    const handleCloseAddEncryptionToken = () => setOpenAddEncryptionToken(false);
    const token = sessionStorage.getItem('token');
    const userRole = sessionStorage.getItem("userRole");

    const downloadFile = async (id) => {
        const result = await apiget(`api/policydocument/download/${id}`)
        setUserAction(result)
    }
    const deleteFile = async (id) => {
        const result = await apidelete(`api/policydocument/delete/${id}`)
        setUserAction(result)
    }


    const columns = [
        {
            field: "file",
            headerName: "File",
            flex: 1,
        },

        {
            field: "fileName",
            headerName: "File Name",
            flex: 1,
            cellClassName: "name-column--cell--capitalize"
        },
        {
            field: "encryption_code",
            headerName: "Encryption Code",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                    {params.value && !sessionStorage.getItem('encryptioncodeAccessToken') && (
                        <Button
                            variant='contained'
                            size='small'
                            onClick={handleOpenAddEncryptionToken}
                        >
                            Get Encryption Code
                        </Button>
                    ) || params.value}
                    </>
                )
            }
        },
        {
            field: "createdOn",
            headerName: "CreateOn",
            flex: 1,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toDateString();
            },

        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                const handleFirstNameClick = async () => { downloadFile(params.row._id) };
                const viewUrl = `${constant.baseUrl}api/policydocument/file/${token}/${params.row._id}`;
                const downloadUrl = `${constant.baseUrl}api/policydocument/download/${token}/${params.row._id}`;

                return (
                    <Box onClick={handleFirstNameClick}>
                        <Stack direction={"row"} spacing={2}>
                            <a href={viewUrl} target="_blank"><Button variant='contained' size='small'>View</Button></a>
                            <a href={downloadUrl} target="_blank"><Button variant='contained' size='small'>Download</Button></a>
                            {userRole !== "user" && (<Button variant='outlined' size='small' color='error' onClick={() => deleteFile(params.row._id)}>Delete</Button>)}
                        </Stack>
                    </Box>
                );
            }
        },

    ];

    return (
        <>
            {/* Add Document Model */}
            <AddDocument open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} _id={_id} />
            <EncryptionCode open={openAddEncryptionToken} handleClose={handleCloseAddEncryptionToken} setUserAction={setUserAction}/>

            <Box p={2} style={{ cursor: "pointer" }}>
                <Grid container display="flex" alignItems="center">
                    <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
                        <Stack direction="row" spacing={1} alignItems={"center"}>
                            <Button
                                onClick={toggleVisibilityPolicyDoc}
                                color="secondary"
                                variant="contained"
                                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
                            >
                                {isVisiblePolicyDoc ? <RemoveIcon /> : <AddIcon />}
                            </Button>
                            <AttachmentIcon />
                            <Typography variant="h5">Policy Documents</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                            {isVisiblePolicyDoc && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={handleOpenAdd}
                                >
                                    Add Document
                                </Button>
                            )}
                        </Stack>
                    </Stack>
                </Grid>
            </Box>
            {
                isVisiblePolicyDoc &&
                <TableStyleTwo>
                    <Box width="100%" height="30vh">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            getRowId={row => row._id}
                            columnHeaderHeight={40}
                        />
                    </Box>
                </TableStyleTwo>

            }
        </>
    );
}

export default PolicyDocumentPage