import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid';
import moment from "moment";
import { useEffect, useState } from 'react';
import { CiExport } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import TableStyle from '../../components/TableStyle';
import Iconify from '../../components/iconify';
import { useNavigate, useParams } from 'react-router-dom';
import { commonUtils } from '../../utils/utils';
import AddCertificate from './issueCertificate';
import { apiget } from 'src/service/api';
// ----------------------------------------------------------------------

function CustomToolbar({ selectedRowIds, fetchdata }) {
    const [userAction, setUserAction] = useState(null);
    const userid = sessionStorage.getItem('user_id');
    const dispatch = useDispatch()

    useEffect(() => {
        setUserAction(userAction)
    }, [userAction])

    return (
        <GridToolbarContainer>
            <GridToolbar />
        </GridToolbarContainer>
    );
}

const Certificates = () => {

    const [userAction, setUserAction] = useState(null);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [certificates, setCertificates] = useState({});
    const params = useParams()

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false);
    const userid = sessionStorage.getItem('user_id');
    const userRole = sessionStorage.getItem("userRole");

    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);
    const fetchCertificates = async () => {
        setIsLoading(true)
        try {
            const result = await apiget(`api/dmvic/certificates/${params.id}`);
            if (result && result.status === 200) {
                setCertificates(result?.data?.certificates);
            }
        } catch (error) {
            console.error("Error fetching call data:", error);
        }
        setIsLoading(false)
    };
    const columns = [
        {
            field: "policynumber",
            headerName: "Certificate Number",
            flex: 1,
            cellClassName: "name-column--cell name-column--cell--capitalize",
            renderCell: (params) => {
                const handleFirstNameClick = () => {
                    navigate(`/dashboard/certificate/view/${params.row._id}`)
                };

                return (
                    <Box onClick={handleFirstNameClick}>
                        {params.value}
                    </Box>
                );
            }
        },
        {
            field: "CertificateType",
            headerName: "Certificate Type",
            flex: 1,
            cellClassName: "name-column--cell--capitalize",
        },
        {
            field: "Chassisnumber",
            headerName: "Chassis Number",
            flex: 1,
        },
        {
            field: "Bodytype",
            headerName: "Body Type",
            flex: 1,
        },
        {
            field: "success",
            headerName: "Success",
            flex: 1,
        },
        {
            field: "Commencingdate",
            headerName: "Start Date",
            flex: 1,
        },
        {
            field: "Expiringdate",
            headerName: "Expiry Date",
            flex: 1,
        },
        {
            field: "createdOn",
            headerName: "Created On",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {moment(params?.row?.createdOn).format('lll')}
                    </>
                );
            }
        }
    ];

    const handleSelectionChange = (selectionModel) => {
        setSelectedRowIds(selectionModel);
    };
    const csvColumns = [
        {
            Header: "Certificate Number", accessor: 'policynumber'
        },
        {
            Header: "Chassis Number", accessor: 'Chassisnumber'
        },
        {
            Header: "Certificate Type", accessor: 'CertificateType'
        },
        {
            Header: "Body Type", accessor: 'Bodytype'
        },
        {
            Header: "Success", accessor: 'success'
        },
        {
            Header: "Start Date", accessor: "Commencingdate"
        },
        {
            Header: "Expiry Date", accessor: 'Expiringdate'
        },
        {
            Header: "Created On", accessor: 'createdOn'
        },

    ];

    const downloadCsvOrExcel = async (extension, selectedIds) => {
        const formatDateOfBirth = (dateString, filednm) => {
            return moment(dateString).format('DD/MM/YYYY HH:MM A')
        };

        const formatRecords = (records) => {
            return records.map((rec) => {
                const selectedFieldsData = {};
                csvColumns?.forEach((item) => {
                    if (item?.type === 'date') {
                        selectedFieldsData[item?.accessor] = formatDateOfBirth(rec[item?.accessor]);
                    }
                    else {
                        selectedFieldsData[item?.accessor] = rec[item?.accessor];
                    }
                });
                return selectedFieldsData;
            });
        };

        if (selectedIds && selectedIds?.length > 0) {
            const selectedRecordsWithSpecificFileds = formatRecords(certificates?.filter((rec) => selectedIds?.includes(rec._id)));
            commonUtils.convertJsonToCsvOrExcel({ jsonArray: selectedRecordsWithSpecificFileds, csvColumns, fileName: "Payment", extension, setSelectedRowIds });
        } else {
            const AllRecordsWithSpecificFileds = formatRecords(certificates);
            commonUtils.convertJsonToCsvOrExcel({ jsonArray: AllRecordsWithSpecificFileds, csvColumns, fileName: "Payment", extension, setSelectedRowIds });
        }
    };

    const handleExportPayments = (extension) => {
        if (selectedRowIds && selectedRowIds?.length > 0) {
            downloadCsvOrExcel(extension, selectedRowIds)
        } else {
            downloadCsvOrExcel(extension);
        }
    };

    useEffect(() => {
        fetchCertificates();
    }, [userAction])

    return (
        <>
            <AddCertificate open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} />

            <Container maxWidth>
                <Stack direction="row" alignItems="center" mb={5} justifyContent={"space-between"}>
                    <Typography variant="h4" >
                        Payment
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
                        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                            Add Dmvic Certificate
                        </Button>

                        <Button variant="contained" startIcon={<CiExport icon="eva:plus-fill" />} onClick={() => { handleExportPayments('xlsx') }} >
                            {selectedRowIds && selectedRowIds?.length > 0 ? 'Export Selected Data' : 'Export'}

                        </Button>
                    </Stack>
                </Stack>
                <TableStyle>
                    <Box width="100%">
                        {isLoading ? (
                            <Card style={{ display: 'flex', justifyContent: 'center', height: "600px" }}>
                                <span className="loader" />
                            </Card>
                        ) : (
                            <Card style={{ height: "600px" }}>
                                <DataGrid
                                    rows={certificates}
                                    columns={columns}
                                    components={{
                                        Toolbar: () => (<Box padding={"10px 0"}>
                                            <GridToolbarColumnsButton />
                                            <GridToolbarFilterButton />
                                            <GridToolbarDensitySelector
                                                slotProps={{ tooltip: { title: 'Change density' } }}
                                            />
                                        </Box>)
                                    }}
                                    checkboxSelection
                                    onRowSelectionModelChange={handleSelectionChange}
                                    rowSelectionModel={selectedRowIds}
                                    getRowId={row => row._id}
                                />
                            </Card>
                        )}

                    </Box>
                </TableStyle>
            </Container>
        </>
    );
}

export default Certificates