import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Checkbox, CircularProgress, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, TextField, ListItemText} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { apipost } from '../../service/api';
import { fetchWACampaignData } from '../../redux/slice/WAcampaignSlice';
import { useDispatch } from 'react-redux';


const Send = (props) => {
    const { open, handleClose, setUserAction, } = props
    const [isLoading, setIsLoading] = useState(false);
    const waCampaigns = useSelector((state) => state?.WAcampaignDetails?.data);
    const WAContacts = useSelector((state) => state?.WAcontactDetails?.data)
    const dispatch = useDispatch()

    // -----------  validationSchema
    const validationSchema = yup.object({
        phoneNumber: yup.string().required('Phone Number is invalid'),
        message: yup.string().required('Message is required'),
        campaing_id: yup.string().required('Campaign is required'),
    });

    // -----------   initialValues
    const initialValues = {
        phoneNumber: "",
        message: "",
        campaing_id: "",
    };

    // add policy api
    const sendWACampaign = async (values) => {
        setIsLoading(true);
        try {
            const data = {
                phoneNumber: values.phoneNumber,
                message: values.message,
                campaing_id: values.campaing_id,
            }
            const result = await apipost('api/whatsapp/sendcampaign', data)

            setUserAction(result)

            if (result && result.status === 201) {
                formik.resetForm();
                handleClose();
            }
        } catch (error) {
            console.error('Error sending campaign:', error);
        }
        setIsLoading(false);
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            sendWACampaign(values)
        },
    });
    useEffect(() => {
        if (waCampaigns?.length === 0) {
            dispatch(fetchWACampaignData())
        }
    }, [open])
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Add New </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography style={{ marginBottom: "15px" }} variant="h6">
                                Whats App Contact Details
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Phone Number</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        size="small"
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    >
                                        {
                                            WAContacts?.map((contact) =>{ 
                                                return (
                                                    <MenuItem value={contact?.phone}>
                                                        {`${contact?.name}`}
                                                    </MenuItem>
                                                )}
                                            )
                                        }
                                    </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
                                            }
                                        >
                                        {formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Message </FormLabel>
                                    <TextField
                                        id="message"
                                        name="message"
                                        size='small'
                                        fullWidth
                                        multiline
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        error={formik.touched.message && Boolean(formik.errors.message)}
                                        helperText={formik.touched.message && formik.errors.message}
                                    />
                                </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Campaign</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="campaing_id"
                                        name="campaing_id"
                                        size="small"
                                        multiple
                                        value={formik.values.campaing_id}
                                        onChange={formik.handleChange}
                                        error={formik.touched.campaing_id && Boolean(formik.errors.campaing_id)}
                                    >
                                        {
                                            waCampaigns?.map((campaign) =>{ 
                                                return (
                                                    <MenuItem value={campaign?.id}>
                                                        {`${campaign?.name}`}
                                                    </MenuItem>
                                                )}
                                            )
                                        }
                                    </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.campaing_id && Boolean(formik.errors.campaing_id)
                                            }
                                        >
                                        {formik.touched.campaing_id && formik.errors.campaing_id}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancle</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Send