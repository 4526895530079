/* eslint-disable react/prop-types */
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import ReactQuill from 'react-quill';
import * as yup from "yup";
// eslint-disable-next-line import/no-unresolved
import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormControl, FormLabel, Select, MenuItem, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apipost } from "../../service/api";
import { certificateTypes } from "src/_mock/dmvicCertTypes";


const AddCertificate = (props) => {
    const { open, handleClose, setUserAction, data } = props
    const dispatch = useDispatch()
    const [CertificateType, setCertificateType] = useState("");
    const [CertificateCategories, setCertificateCategories] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const userDetails = useSelector((state) => state?.userDetails?.data)
    const params = useParams()

    const user = JSON.parse(sessionStorage.getItem('user'))

    // -----------  validationSchema
    const validationSchema = yup.object({
        MemberCompanyID: yup.string().required("Member Company is required"),
        CertificateType: yup.string().required("Type of Certificate is required"),
        Typeofcover: yup.string().required("Type of Cover is required"),
        Policyholder: yup.string().required("Policy Holder is required"),
        policynumber: yup.string().required("Certificate Number is required"),
        Commencingdate: yup.string().required("Start Date is required"),
        Expiringdate: yup.string().required("Expiring Date is required"),
        Chassisnumber: yup.string().required("Vehicle Chassis Number is required"),
        Phonenumber: yup.string().required("Phone Number is required"),
        Bodytype: yup.string().required("Vehicle Body Type is required"),
        Email: yup.string().required("Email is required"),
        InsuredPIN: yup.string().required("KRA PIN is required"),
    });
    // -----------   initialValues
    const initialValues = {
        MemberCompanyID: "",
        CertificateType: "",
        Typeofcover: "",
        TypeOfCertificate: "",
        Policyholder: "",
        policynumber: "",
        Commencingdate: "",
        Expiringdate: "",
        Registrationnumber: "",
        Chassisnumber: "",
        VehicleType: "",
        Phonenumber: "",
        Bodytype: "",
        Vehiclemake: "",
        InsuredPIN:  "",
        Email: "certificates@anzianoinsuranceagency.com",
        Licensedtocarry: "",
        SumInsured: "",
        Tonnage: "",
        createdBy: user?._id,
        agency_id: user?.agency_id,
        payment_id: params?.id,
    };

    // add email api
    const addCertificate = async (values) => {
        setIsLoading(true)

        try {
            const data = values;
            const result = await apipost(`api/dmvic/issuecertificates/${values.CertificateType}`, data)
            setUserAction(result)
            if (result && (result.status >= 200 && result.status < 300)) {
                formik.resetForm();
                handleClose();
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }
    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        validate: (values) => {
            const errors = {};
            if (values.Typeofcover === '100' && !values.SumInsured)
                errors.SumInsured = 'Sum Insured is required';
            if ((CertificateType === 'TypeD' || CertificateType === 'TypeA') && !values.TypeOfCertificate)
                errors.TypeOfCertificate = 'Certificate category is required';
            if ((CertificateType === 'TypeB' || (CertificateType === 'TypeD' && values.TypeOfCertificate === '10')) && !values.Tonnage)
                errors.Tonnage = 'Tonnage is required';
            if (CertificateType === 'TypeB'  && !values.VehicleType)
                errors.TypeOfCertificate = 'Vehicle Type is required';
            if ((CertificateType === 'TypeA' || CertificateType === 'TypeB' ) && !values.Licensedtocarry)
                errors.Licensedtocarry = 'License to carry is required';
            if ((CertificateType === 'TypeD' && (values.TypeOfCertificate === '4' || values.TypeOfCertificate === '9') ) && !values.Licensedtocarry)
                errors.Licensedtocarry = 'License to carry is required';
            return errors;
        },
        enableReinitialize: true,
        onSubmit: async (values, { resetForm }) => {
            addCertificate(values);
            resetForm();
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Issue Certificate</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Member Company</FormLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="MemberCompanyID"
                                        name="MemberCompanyID"
                                        label=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.MemberCompanyID}
                                        onChange={formik.handleChange}
                                        >
                                        <MenuItem value="43">MONARCH INSURANCE</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                 <Grid item xs={12} sm={4} md={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Type of Certificate</FormLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="CertificateType"
                                        name="CertificateType"
                                        label=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.CertificateType}
                                        onChange={(e) => {
                                            setCertificateType(e.target.value);
                                            setCertificateCategories(certificateTypes.filter((certificate) => certificate.type === e.target.value))
                                            formik.setFieldValue('CertificateType', e.target.value);
                                        }}
                                        >
                                            
                                        <MenuItem value="TypeA">Type A</MenuItem>
                                        <MenuItem value="TypeB">Type B - Commercial Vehicle </MenuItem>
                                        <MenuItem value="TypeC">Type C - Private Car </MenuItem>
                                        <MenuItem value="TypeD"> Type D - Motorcycle, PSV </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {(CertificateType === 'TypeD' || CertificateType === 'TypeA') && (<Grid item xs={12} sm={4} md={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Select Certificate Type Category</FormLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="TypeOfCertificate"
                                        name="TypeOfCertificate"
                                        label=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.TypeOfCertificate}
                                        onChange={formik.handleChange}
                                        >
                                        {CertificateCategories?.map((item) => {
                                            return (
                                                <MenuItem key={item.value} value={item.value}>
                                                {`${item.label}`}
                                                </MenuItem>
                                            );
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>)}
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Type of Cover</FormLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="Typeofcover"
                                        name="Typeofcover"
                                        label=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.Typeofcover}
                                        onChange={formik.handleChange}

                                        >
                                        <MenuItem value="100">Comprehensive (COMP)</MenuItem>
                                        <MenuItem value="200">Third-party (TPO)</MenuItem>
                                        <MenuItem value="300">Third-party, Theft & Fire (TPTF)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Policy Holder </FormLabel>
                                    <TextField
                                        name='Policyholder'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Policyholder}
                                        onChange={formik.handleChange}
                                        error={formik.touched.Policyholder && Boolean(formik.errors.Policyholder)}
                                        helperText={formik.touched.Policyholder && formik.errors.Policyholder}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Certificate Number </FormLabel>
                                    <TextField
                                        name='policynumber'
                                        size='small'
                                        fullWidth
                                        value={formik.values.policynumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.policynumber && Boolean(formik.errors.policynumber)}
                                        helperText={formik.touched.policynumber && formik.errors.policynumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Vehicle Registration Number </FormLabel>
                                    <TextField
                                        name='Registrationnumber'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Registrationnumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.Registrationnumber && Boolean(formik.errors.Registrationnumber)}
                                        helperText={formik.touched.Registrationnumber && formik.errors.Registrationnumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Vehicle Chassis Number </FormLabel>
                                    <TextField
                                        name='Chassisnumber'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Chassisnumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.Chassisnumber && Boolean(formik.errors.Chassisnumber)}
                                        helperText={formik.touched.Chassisnumber && formik.errors.Chassisnumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Policyholder KRA Pin </FormLabel>
                                    <TextField
                                        name='InsuredPIN'
                                        size='small'
                                        fullWidth
                                        value={formik.values.InsuredPIN}
                                        onChange={formik.handleChange}
                                        error={formik.touched.InsuredPIN && Boolean(formik.errors.InsuredPIN)}
                                        helperText={formik.touched.InsuredPIN && formik.errors.InsuredPIN}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Policyholder Phone Number </FormLabel>
                                    <TextField
                                        name='Phonenumber'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Phonenumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.Phonenumber && Boolean(formik.errors.Phonenumber)}
                                        helperText={formik.touched.Phonenumber && formik.errors.Phonenumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Vehicle Body Type</FormLabel>
                                    <TextField
                                        name='Bodytype'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Bodytype}
                                        onChange={formik.handleChange}
                                        error={formik.touched.Bodytype && Boolean(formik.errors.Bodytype)}
                                        helperText={formik.touched.Bodytype && formik.errors.Bodytype}
                                    />
                                </Grid>
                                {CertificateType === 'TypeB' && (<Grid item xs={12} sm={6} md={6}>
                                    <FormControl fullWidth>
                                        <FormLabel>Vehicle Type</FormLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="VehicleType"
                                        name="VehicleType"
                                        label=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.VehicleType}
                                        onChange={formik.handleChange}
                                        >
                                        <MenuItem value="1">Motor Commercial Own Goods</MenuItem>
                                        <MenuItem value="2">Motor Commercial General Cartage</MenuItem>
                                        <MenuItem value="3">Motor Institutional Vehicle</MenuItem>
                                        <MenuItem value="4">Motor Special Vehicles</MenuItem>
                                        <MenuItem value="5">Tankers (Liquid Carrying)</MenuItem>
                                        <MenuItem value="6">Motor Trade (Road Risk)</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>)}
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Vehicle Make</FormLabel>
                                    <TextField
                                        name='Vehiclemake'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Vehiclemake}
                                        onChange={formik.handleChange}
                                        error={formik.touched.Vehiclemake && Boolean(formik.errors.Vehiclemake)}
                                        helperText={formik.touched.Vehiclemake && formik.errors.Vehiclemake}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Start Date</FormLabel>
                                    <TextField
                                        id="Commencingdate"
                                        name="Commencingdate"
                                        type='date'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Commencingdate}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Expiry Date</FormLabel>
                                    <TextField
                                        id="Expiringdate"
                                        name="Expiringdate"
                                        type='date'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Expiringdate}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                {(CertificateType === 'TypeA' || CertificateType === 'TypeB' || CertificateType === 'TypeD') && (<Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Licensed To Carry</FormLabel>
                                    <TextField
                                        id="Licensedtocarry"
                                        name="Licensedtocarry"
                                        type='number'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Licensedtocarry}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>)}
                                {(CertificateType === 'TypeB' || CertificateType === 'TypeD') && (<Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Tonnage</FormLabel>
                                    <TextField
                                        id="Tonnage"
                                        name="Tonnage"
                                        type='number'
                                        size='small'
                                        fullWidth
                                        value={formik.values.Tonnage}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>)}
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Sum Insured</FormLabel>
                                    <TextField
                                        id="SumInsured"
                                        name="SumInsured"
                                        type='number'
                                        size='small'
                                        fullWidth
                                        value={formik.values.SumInsured}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='secondary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                        color="error"
                    >
                        Cancle
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddCertificate