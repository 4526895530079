/* eslint-disable no-useless-catch */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiget } from '../../service/api';

export const fetchAgencyData = createAsyncThunk('fetchAgencyData', async () => {
    const agency = JSON.parse(sessionStorage.getItem("agency"));
    try {
        const response = await apiget("api/agency/");
        return response?.data?.result;
    } catch (error) {
        throw error;
    }
});

const agencySlice = createSlice({
    name: 'agencyDetails',
    initialState: {
        data: [],
        isLoading: false,
        error: "",
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgencyData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAgencyData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.error = "";
            })
            .addCase(fetchAgencyData.rejected, (state, action) => {
                state.isLoading = false;
                state.data = [];
                state.error = action.error.message;
            });
    },
});

export default agencySlice.reducer;