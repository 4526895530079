import { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, FormLabel, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { apipost } from '../../service/api';

const EncryptionCode = (props) => {
    const { open, handleClose, setUserAction } = props

    const userid = sessionStorage.getItem('user_id')
    const userRole = sessionStorage.getItem("userRole")
    const userDetails = useSelector((state) => state?.userDetails?.data)
    const [isLoading, setIsLoading] = useState(false);
    const userdata = JSON.parse(sessionStorage.getItem('user'));

    // -----------  validationSchema
    const validationSchema = yup.object({
        otp: yup.string().required("OTP is required"),
    });

    // -----------   initialValues
    const initialValues = {
        otp: "",
    };

    // add policy api
    const addCodeAccessOTP = async (values) => {
        setIsLoading(true);
        try {
            const result = await apipost('api/encryptioncodeaccess/add', {})
            setUserAction(result);
        } catch (error) {
            console.error('Error creating otp:', error);
        }
        setIsLoading(false);
    }
    const verifyCodeAccessOTP = async (values) => {
      setIsLoading(true);
      try {
          const data = values
          const result = await apipost('api/encryptioncodeaccess/view', data)
          setUserAction(result)

          if (result && result.status === 201) {
              sessionStorage.setItem('encryptioncodeAccessToken', JSON?.stringify(result?.data?.docToken?.token));
              formik.resetForm();
              handleClose();
          }
      } catch (error) {
          console.error('Error verifying otp:', error);
      }
      setIsLoading(false);
  }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            verifyCodeAccessOTP(values);
        },
    });

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Document Encryption Codes Access </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography
                                style={{ marginBottom: "15px", marginTop: "15px" }}
                                variant="h6"
                            >
                                Enter Encryption Code Access OTP
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>OTP </FormLabel>
                                    <TextField
                                        id="otp"
                                        name="otp"
                                        size='small'
                                        type='number'
                                        fullWidth
                                        value={formik.values.otp}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.otp &&
                                            Boolean(formik.errors.otp)
                                        }
                                        helperText={
                                            formik.touched.otp && formik.errors.otp
                                        }
                                    />
                                </Grid>
                            </Grid>

                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {
                          addCodeAccessOTP();
                      }} variant='contained' color='secondary'>Create</Button>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Verify'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EncryptionCode;