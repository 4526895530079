export const cancellationReasons = [
    {label: 'Incorrect Policy holder data entry', value: 1},
    {label: 'Incorrect Policy data entry', value: 2},
    {label: 'Incorrect Vehicle data entry', value: 3},
    {label: 'Fraudulent insurance', value: 4},
    {label: 'Confidential reasons', value: 5},
    {label: 'Broker payment not received', value: 6},
    {label: 'Insured person didn’t make payments due', value: 7},
    {label: 'Insured person requested cancellation', value: 8},
    {label: 'Stolen Vehicle', value: 9},
    {label: 'Salvaged Vehicle', value: 10},
    {label: 'Amending no of passengers', value: 12},
    {label: 'Change of scope of cover', value: 13},
    {label: 'Policy Not taken up', value: 14},
    {label: 'Vehicle sold', value: 15},
    {label: 'Wrong dates', value: 16},
    {label: 'Wrong registration number', value: 17},
    {label: 'Amending Insured’s Details', value: 18},
    {label: 'Amending vehicle details', value: 19},
    {label: 'Suspected Fraud', value: 20},
    {label: 'Non-payment of premium', value: 21},
    {label: 'Cancellation requested by Insured', value: 22},
    {label: 'Change of Ownership', value: 23},
    {label: 'Failure to provide KYCs', value: 24},
    {label: 'Request by a government body', value: 25},
    {label: 'Subject matter ceased to exist', value: 26},
    {label: 'Change Period of Insurance', value: 27},
    {label: 'Cover declined by Insurer', value: 28},
    {label: 'Cancellation requested by Insurer', value: 29},

]	