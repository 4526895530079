import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Dialog, FormLabel, InputAdornment } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Iconify from '../../components/iconify';
import IconButton from "@mui/material/IconButton";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { apipost } from "../../service/api";

const Add = (props) => {
  const { handleClose, open, setUserAction } = props
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const userdata = JSON.parse(sessionStorage.getItem('user'));

  // -----------  validationSchema
  const validationSchema = yup.object({
    firstName: yup.string().required("Frist Name is required"),
    lastName: yup.string().required("Last Name is required"),
    emailAddress: yup.string().email('Invalid email').required("Email is required"),
    phoneNumber: yup.string().matches(/[0-9]{12}$/, 'Invalid Phone Number').required("Phone Number is required"),
    password: yup.string().required("Password is required"),
    confirmPassword: yup.string().required("Password is required").oneOf([yup.ref("password"), null], "Password fields must be equal")
  });

  // -----------   initialValues
  const initialValues = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    agency_id: userdata?.agency_id,
  };

  // add user api
  const addUser = async (values) => {
    setIsLoading(true)
    try {
      const result = await apipost('api/user/register', values)
      setUserAction(result)
      if (result && result.status === 201) {
        formik.resetForm();
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
        const userData = {
            firstName: values.firstName,
            lastName: values.lastName,
            emailAddress: values.emailAddress,
            phoneNumber: values.phoneNumber,
            password: values.password,
        }
      addUser(userData)
    },
  });

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">Add New </Typography>
          <Typography>
            <ClearIcon
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 0, sm: 5, md: 4 }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel>First Name <span style={{ color: "red" }}>*</span></FormLabel>
                <TextField
                  id="firstName"
                  name="firstName"
                  label=""
                  size="small"
                  maxRows={10}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  fullWidth
                  error={
                    formik.touched.firstName &&
                    Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel>Last Name <span style={{ color: "red" }}>*</span></FormLabel>
                <TextField
                  id="lastName"
                  name="lastName"
                  label=""
                  size="small"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  fullWidth
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={
                    formik.touched.lastName && formik.errors.lastName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel>Email <span style={{ color: "red" }}>*</span></FormLabel>
                <TextField
                  id="emailAddress"
                  name="emailAddress"
                  label=""
                  size="small"
                  value={formik.values.emailAddress}
                  onChange={formik.handleChange}
                  fullWidth
                  error={
                    formik.touched.emailAddress &&
                    Boolean(formik.errors.emailAddress)
                  }
                  helperText={
                    formik.touched.emailAddress && formik.errors.emailAddress
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel>Phone Number <span style={{ color: "red" }}>*</span></FormLabel>
                <TextField
                  id="phoneNumber"
                  name="phoneNumber"
                  label=""
                  size="small"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  fullWidth
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel>Password <span style={{ color: "red" }}>*</span></FormLabel>
                <TextField
                  id="password"
                  name="password"
                  label=""
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  error={
                    formik.touched.password &&
                    Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password && formik.errors.password
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FormLabel>Confirm Password <span style={{ color: "red" }}>*</span></FormLabel>
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  label=""
                  size="small"
                  type={showPassword ? 'text' : 'password'}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword && formik.errors.confirmPassword
                  }
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
            {isLoading ? <CircularProgress size={27} /> : 'Save'}
          </LoadingButton>
          <Button
            type="reset"
            variant="outlined"
            color="error"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              formik.resetForm()
              handleClose()
            }}
          >
            Cancle
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Add