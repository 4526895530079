export const certificateTypes = [
    {type: 'TypeA', label: 'Class A - PSV unmarked', value: 1},
    {type: 'TypeA', label: 'Type A TAXI', value: 8},
    {type: 'TypeD', label: 'Type D Motor Cycle', value: 4},
    {type: 'TypeD', label: 'Type D PSV Motor Cycle', value: 9},
    {type: 'TypeD', label: 'Type D Motor Cycle Commercial', value: 10},
]
// 1. Type C- Motor Private -HDO/0700/558509/2024
// 2. Type B - Motor Commercial-HD0/0800/007371/2024
// 3. Type D - Motor Cycle-HDO/0701/002321/2024
// 4. Type D -  Motor Cycle  PSV-HDO/0810/004991/2024