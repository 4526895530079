import { useEffect, useState } from 'react';
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, FormLabel, Grid, TextField,} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from "yup";
import { LoadingButton } from '@mui/lab';
import { apipost } from '../../service/api';
import { fetchWAContactData } from '../../redux/slice/WAcontactSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';


const Send = (props) => {
    const { open, handleClose, setUserAction, contactData } = props
    const [isLoading, setIsLoading] = useState(false);
    const [contact, setContact] = useState({});
    const dispatch = useDispatch()
    const params = useParams()
    // -----------  validationSchema
    const validationSchema = yup.object({
        message: yup.string().required('Message is required'),
    });
    console.log(contactData);
    console.log(contactData.phone);
    const Contacts = useSelector((state) => state?.WAcontactDetails?.data);
    
    // -----------   initialValues
    const initialValues = {
        phoneNumber: contact?.phone,
        message: "",
    };

    const fetchContact = async () => {
        const contact = Contacts.find((contact)=> contact.id === Number(params.id)) || {}
        setContact(contact);
        console.log(contact);
    }
    // add policy api
    const sendMsg = async (values) => {
        setIsLoading(true);
        try {
            const data = {
                phoneNumber: values.phoneNumber,
                message: values.message,
            }
            const result = await apipost('api/whatsapp/sendmessage', data)

            setUserAction(result)

            if (result && result.status === 201) {
                formik.resetForm();
                handleClose();
            }
        } catch (error) {
            console.error('Error sending whatsapp message:', error);
        }
        setIsLoading(false);
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            sendMsg(values)
        },
    });
    useEffect(() => {
        if (params.id) {
            if (Object.keys(Contacts).length === 0)
                dispatch(fetchWAContactData());
          fetchContact();
        }
      }, [contact])
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Send Message </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography style={{ marginBottom: "15px" }} variant="h6">
                                Whats App Contact Details
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Phone Number </FormLabel>
                                    <TextField
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        size='small'
                                        fullWidth
                                        disabled
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormLabel>Message </FormLabel>
                                    <TextField
                                        id="message"
                                        name="message"
                                        size='small'
                                        fullWidth
                                        multiline
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        error={formik.touched.message && Boolean(formik.errors.message)}
                                        helperText={formik.touched.message && formik.errors.message}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancle</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Send;