/* eslint-disable react/prop-types */
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import EmailIcon from "@mui/icons-material/Email";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from 'react-router-dom';
import AddPayment from './AddDmvicPayment';
import TableStyleTwo from '../TableStyleTwo';

const Payments = ({ rows, toggleVisibilityDmvicPayment, isVisibleDmvicPayment, _id, setUserAction, data }) => {

  const [openEmail, setOpenNote] = useState(false);
  const navigate = useNavigate();

  // open note model
  const handleOpenEmail = () => setOpenNote(true);
  const handleCloseEmail = () => setOpenNote(false);


  const columns = [
    {
      field: "orderId",
      headerName: "Order Id",
      flex: 1,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/dmvicpayment/view/${params.row._id}`)
        };

        return (
          <Box onClick={handleFirstNameClick}>
            {params.value}
          </Box>
        );
      }
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  ];

  return (
    <div>
      {/* Add email */}
      <AddPayment open={openEmail} handleClose={handleCloseEmail} _id={_id} setUserAction={setUserAction} />

      <Box
        style={{ cursor: "pointer" }}
        p={2}
      >
        <Grid container display="flex" alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent={"space-between"} width={"100%"}>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <Button
                onClick={toggleVisibilityDmvicPayment}
                color="secondary"
                variant="contained"
                sx={{ width: "28px", minWidth: "0px", padding: "0px", height: "25px" }}
              >
                {isVisibleDmvicPayment ? <RemoveIcon /> : <AddIcon />}
              </Button>
              <EmailIcon />
              <Typography variant="h5">Certificate Payments</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent={"flex-end"} spacing={2}>
              {isVisibleDmvicPayment && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleOpenEmail}
                >
                  Create Payment
                </Button>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Box>
      {isVisibleDmvicPayment &&
        <TableStyleTwo>
          <Box width="100%" height="30vh">
            <DataGrid
              rows={rows}
              columns={columns}
              columnHeaderHeight={40}
              getRowId={row => row._id}

            />
          </Box>
        </TableStyleTwo>
      }

    </div>
  )
}

export default Payments
