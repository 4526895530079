import { LoadingButton } from "@mui/lab";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Container, CircularProgress, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { apipost } from "../../../service/api";
import { useNavigate } from 'react-router-dom';
import { StyledRoot, StyledSection, StyledContent } from "../forgotpassword/styleConf";
import useResponsive from '../../../hooks/useResponsive';


export default function SubscribeAgency() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const mdUp = useResponsive('up', 'md');


    // -----------  validationSchema
    const validationSchema = yup.object({
        emailAddress: yup.string().email('Invalid email').required("Email is required"),
        agency_code: yup.string().required("Agency Code is required"),
    });

    // -----------   initialValues
    const initialValues = {
        emailAddress: "",
        agency_code: "",
        amount: "1"
    };

    // edit api
    const subscribe = async (values) => {
        setIsLoading(true)

        try {
            const result = await apipost('api/agency/subscribe', values)
            if (result && result.status === 200) {
                navigate('/login')
              } else {
                navigate('/subscribe')
              }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }
    const back = () => {
        navigate('/login');
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            const userData = {
                emailAddress: values.emailAddress,
                agency_code: values.agency_code
            }
            subscribe(userData)
        },
    });
    return (
        <>
        <StyledRoot>
        {mdUp && (
            <StyledSection>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>Email Address Verification </Typography>
                <img src="/assets/illustrations/illustration_login.png" alt="Forgot Password" />
            </StyledSection>
        )}
        <Container maxWidth="sm">
            <StyledContent>
            <img src="/assets/AIA.png" width={200} style={{ marginLeft: "-7px" }} />
            <Typography variant="h4" gutterBottom mb={4}>Enter your Email Address</Typography>
            <form>
                <Stack spacing={3} mb={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <FormLabel>Agency Code <span style={{ color: "red" }}>*</span></FormLabel>
                    <TextField
                        id="agency_code"
                        name="agency_code"
                        label=""
                        size="small"
                        value={formik.values.agency_code}
                        onChange={formik.handleChange}
                        fullWidth
                        error={
                            formik.touched.agency_code &&
                            Boolean(formik.errors.agency_code)
                        }
                        helperText={
                            formik.touched.agency_code && formik.errors.agency_code
                        }
                    />
                </Grid>
                </Stack>
                <Stack spacing={3} mb={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormLabel>Email Address <span style={{ color: "red" }}>*</span></FormLabel>
                        <TextField
                        id="emailAddress"
                        name="emailAddress"
                        label=""
                        size="small"
                        type={'email'}
                        value={formik.values.emailAddress}
                        onChange={formik.handleChange}
                        fullWidth
                        error={
                            formik.touched.emailAddress &&
                            Boolean(formik.errors.emailAddress)
                        }
                        helperText={
                            formik.touched.emailAddress && formik.errors.emailAddress
                        }
                        />
                    </Grid>
                </Stack>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Send Subscription STK Push'}
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Button variant="contained" color="secondary" onClick={back} startIcon={<ArrowBackIosIcon />}>Back to Login</Button>
                    </Grid>
                </Grid>
            </form>
            </StyledContent>
        </Container>
        </StyledRoot> 
        </>
    )
}