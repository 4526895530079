/* eslint-disable react/prop-types */
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import ReactQuill from 'react-quill';
import * as yup from "yup";
// eslint-disable-next-line import/no-unresolved
import { LoadingButton } from "@mui/lab";
import { CircularProgress, FormControl, FormLabel, Select, MenuItem } from "@mui/material";
import { apiput } from "../../../service/api";
import { certificateTypes } from "src/_mock/dmvicCertTypes";
import { fetchUnderwriterData } from "src/redux/slice/underwriterSlice";


const Edit = (props) => {
    const { open, handleClose, setUserAction, data } = props
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()

    const params = useParams()
    const underwriters = useSelector((state) => state?.underwriterDetails?.data) || []

    const user = JSON.parse(sessionStorage.getItem('user'))

    // -----------  validationSchema
    const validationSchema = yup.object({
        TypeOfCertificate: yup.string().required("Type of Certificate is required"),
        PolicyNumber: yup.string().required("Certificate Number is required"),
    });
    // -----------   initialValues
    const initialValues = {
        PolicyNumber: data?.PolicyNumber,
        TypeOfCertificate: data?.TypeOfCertificate,
    };

    // add email api
    const editPolicyNumber = async (values) => {
        setIsLoading(true)

        try {
            const result = await apiput(`api/policynumber/edit/${data._id}`, values)
            setUserAction(result)
            if (result && (result.status >= 200 && result.status < 300)) {
                formik.resetForm();
                handleClose();
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }
    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            editPolicyNumber(values);
        },
    });
    useEffect(() => {
        dispatch(fetchUnderwriterData())
    }, [open]);
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Issue Certificate</Typography>
                    <Typography>
                        <ClearIcon
                            onClick={() => {
                                formik.resetForm();
                                handleClose();
                            }}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                               <Grid item xs={12} sm={4} md={4}>
                                    <FormControl fullWidth>
                                        <FormLabel>Type of Certificate</FormLabel>
                                        <Select
                                        labelId="demo-simple-select-label"
                                        id="TypeOfCertificate"
                                        name="TypeOfCertificate"
                                        label=""
                                        size='small'
                                        fullWidth
                                        value={formik.values.TypeOfCertificate}
                                        onChange={formik.handleChange}
                                        >
                                        {
                                            certificateTypes.map((type) => {
                                                return (
                                                    <MenuItem key={type.value} value={type.value}>
                                                        {`${type.label}`}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Policy Number </FormLabel>
                                    <TextField
                                        name='PolicyNumber'
                                        size='small'
                                        fullWidth
                                        value={formik.values.PolicyNumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.PolicyNumber && Boolean(formik.errors.PolicyNumber)}
                                        helperText={formik.touched.PolicyNumber && formik.errors.PolicyNumber}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='secondary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button
                        type="reset"
                        variant="outlined"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                            formik.resetForm()
                            handleClose()
                        }}
                        color="error"
                    >
                        Cancle
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Edit