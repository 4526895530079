import { Box, Button, Card, Container, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SendMessageTemplate from './SendTemplateMsg';
import TableStyle from '../../components/TableStyle';
import { fetchWAconversationsData } from '../../redux/slice/WAconversationSlice';
import { fetchWAContactData } from '../../redux/slice/WAcontactSlice';
import Iconify from '../../components/iconify/Iconify';
import SendCampaign from './SendCampaign';
// ----------------------------------------------------------------------


const WAConversation = () => {

  const [userAction, setUserAction] = useState(null)
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data, isLoading } = useSelector((state) => state?.WAconversationDetails)
  const [openSendCampaign, setOpenSendCampaign] = useState(false);
  const [openSendTemplateMsg, setOpenSendTemplateMsg] = useState(false);
  const handleCloseSendCampaign = () => {
    setOpenSendCampaign(false)
  }

  const handleOpenSendCampaign = () => {
    setOpenSendCampaign(true)
  }
  const handleCloseSendTemplateMsg = () => {
    setOpenSendTemplateMsg(false)
  }

  const handleOpenSendTemplateMsg = () => {
    setOpenSendTemplateMsg(true)
  }

  useEffect(() => {
    dispatch(fetchWAconversationsData());
    dispatch(fetchWAContactData());
  }, [userAction])

  return (
    <>
      {/* Add Lead Model */}
      <SendMessageTemplate open={openSendTemplateMsg} handleClose={handleCloseSendTemplateMsg} setUserAction={setUserAction} />
      <SendCampaign open={openSendCampaign} handleClose={handleCloseSendCampaign} setUserAction={setUserAction} />

      <Container maxWidth>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" >
            WhatsApp Conversations
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenSendTemplateMsg}>
              Send Message Template
            </Button>
            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenSendCampaign}>
              Send Campaign
            </Button>
          </Stack>
        </Stack>
        <TableStyle>
          <Box width="100%">
            {isLoading ? (
              <Card style={{ display: 'flex', justifyContent: 'center', height: "600px" }}>
                <span className="loader" />
              </Card>
            ) : (
              <Card style={{ padding: '20px', display: 'flex', flexDirection: 'column', overflow: 'auto', height: "600px", rowGap: '20px'}}>
                  {
                    data.map(
                      (conversation) => {
                          return (<div onClick={()=>{
                            navigate(`/dashboard/whatsapp/contacts/${conversation.id}`)
                          }} style={{height: '20%', display: 'flex', columnGap: '10px'}}>
                            <div className='conversation_img' style={{borderRadius: '50%', background: '#103996', height: '70px', width: '70px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                              <span style={{color: 'white'}}>{conversation.name ? conversation.name[0] : 'A'}</span>
                            </div>
                            <div className='conversation_details'>
                              <span style={{fontWeight: 'bold'}}>{conversation.name}</span>
                              <p style={{overflowWrap: 'anywhere'}}>{conversation.last_message}</p>
                            </div>
                            <div>
                            <span style={{fontSize: '0.7em'}}>{moment(conversation.created_at).format("DD/MM/YYYY, h:mm a")}</span>
                            </div>
                          </div>);
                      }
                    )
                  }
                
              </Card>
            )}


          </Box>
        </TableStyle>
      </Container>
    </>
  );
}

export default WAConversation