import { Box, Card, Grid, Rating, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import Palette from '../../theme/palette'

const Moreinformation = ({ data, isLoading }) => {
    // open instagram

    return (
        <div>
            {isLoading ? (
                <Card style={{ display: 'flex', justifyContent: 'center', paddingBottom: "70px" }}>
                    <span className="loader" />
                </Card>
            ) : (
                <Card style={{ borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}>
                    <Box p={3}>
                        <Grid container display="flex" spacing={4}>
                            <Grid item xs={12} sm={6} >
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="h4" >Vehicle Details :-</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="body1">Vehicle Registration Number :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.Registrationnumber ? data?.Registrationnumber : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Vehicle Chassis Number :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.Chassisnumber ? data?.Chassisnumber : "--"}</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="body1">Vehicle Body Type :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.Bodytype ? data?.Bodytype : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Vehicle Make :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.Vehiclemake ? data?.Vehiclemake : "--"}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="body1">Vehicle Type :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]} >
                                        {data?.VehicleType ? data?.VehicleType : "--"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} pb={2}>
                                    <Typography variant="body1">Passengers Licensed To Carry :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.Licensedtocarry ? data?.Licensedtocarry : "--"}</Typography>
                                </Grid>
                                <Grid style={{ borderBottom: "1.5px dashed", borderBottomColor: Palette.grey[400] }} py={2}>
                                    <Typography variant="body1">Vehicle Tonnage :</Typography>
                                    <Typography variant="body2" color={Palette.grey[600]}>{data?.Tonnage ? data?.Tonnage : "--"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            )}

        </div>
    )
}

export default Moreinformation
