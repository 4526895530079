import { useEffect, useState } from 'react';
import ClearIcon from "@mui/icons-material/Clear";
import { Checkbox, CircularProgress, FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select, TextField, ListItemText} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from "yup";
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { apipost } from '../../../service/api';
import { fetchWAgroupData } from '../../../redux/slice/WAgroupSlice';
import { useDispatch } from 'react-redux';


const Add = (props) => {
    const { open, handleClose, setUserAction, } = props
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()

    const waGroups = useSelector((state) => state?.WAgroupDetails?.data);
    // -----------  validationSchema
    const validationSchema = yup.object({
        phoneNumber: yup.string().matches(/^[0-9]*$/, 'Phone Number is invalid'),
        name: yup.string().required('Name is required'),
    });

    // -----------   initialValues
    const initialValues = {
        phoneNumber: "",
        name: "",
        groups: [],
    };

    // add policy api
    const addWAContact = async (values) => {
        setIsLoading(true);
        try {
            const data = {
                phoneNumber: values.phoneNumber,
                name: values.name,
                groups: values.groups.join()
            }
            const result = await apipost('api/whatsapp/addcontact', data)

            setUserAction(result)

            if (result && result.status === 201) {
                formik.resetForm();
                handleClose();
            }
        } catch (error) {
            console.error('Error adding whatsapp contact:', error);
        }
        setIsLoading(false);
    }

    // formik
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            addWAContact(values)
        },
    });
    useEffect(() => {
        if (waGroups?.length === 0) {
            dispatch(fetchWAgroupData())
        }
    }, [open])
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography variant="h6">Add New </Typography>
                    <Typography>
                        <ClearIcon
                            onClick={handleClose}
                            style={{ cursor: "pointer" }}
                        />
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <form>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography style={{ marginBottom: "15px" }} variant="h6">
                                Whats App Contact Details
                            </Typography>
                            <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 0, sm: 5, md: 4 }}
                            >
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Phone Number </FormLabel>
                                    <TextField
                                        id=""
                                        name="phoneNumber"
                                        type="number"
                                        size='small'
                                        fullWidth
                                        value={formik.values.phoneNumber}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormLabel>Name </FormLabel>
                                    <TextField
                                        id="name"
                                        name="name"
                                        size='small'
                                        fullWidth
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <FormLabel>Groups</FormLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="groups"
                                        name="groups"
                                        size="small"
                                        multiple
                                        value={formik.values.groups}
                                        onChange={formik.handleChange}
                                        error={formik.touched.groups && Boolean(formik.errors.groups)}
                                        renderValue={(selected) => (
                                            <div>
                                                {
                                                    selected.map((value) => {
                                                        const groupData = waGroups?.find((group) => group.id === value)
                                                        return (
                                                            <div key={value}>
                                                                {`${groupData?.name}`}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                        }
                                    >
                                        {
                                            waGroups?.map((group) => (
                                                <MenuItem value={group?.id}>
                                                    <Checkbox checked={formik.values.groups.indexOf(group?.name) > -1} />
                                                    <ListItemText primary={`${group?.name}`} />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                        <FormHelperText
                                            error={
                                                formik.touched.groups && Boolean(formik.errors.groups)
                                            }
                                        >
                                        {formik.touched.groups && formik.errors.groups}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={formik.handleSubmit} variant='contained' color='primary' disabled={!!isLoading}>
                        {isLoading ? <CircularProgress size={27} /> : 'Save'}
                    </LoadingButton>
                    <Button onClick={() => {
                        formik.resetForm()
                        handleClose()
                    }} variant='outlined' color='error'>Cancle</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Add